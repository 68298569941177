import React from 'react';

function AboutSite() {
    const zero = <>
        <p>Вебсайт http://vitapol.info створений видавничо-інформаційною компанією ТОВ «ВІТ-А-ПОЛ» у 2021 році.</p>
        <p>http://vitapol.info – це спеціалізований портал медичної інформації, призначений винятково для медиків, фармацевтів, працівників із вищою та середньою спеціальною медичною освітою.</p>
        <p>ТОВ «ВІТ-А-ПОЛ», розміщуючи електронні публікації на вебсайті http://vitapol.info, діє відповідно до чинного законодавства України, зокрема Цивільного кодексу України, Господарського кодексу України, Законів України «Про видавничу справу», «Про науково-технічну інформацію», а також згідно зі Статутом та Угодою користувача інформації вебсайту http://vitapol.info.</p>
    </>;

    const one = <>
        <p>Структура вебсайту http://vitapol.info передбачає:</p>
        <ul className="markedList">
            <li>електронні публікації матеріалів спеціалізованих видань ТОВ «ВІТ-А-ПОЛ», ПП «ІНПОЛ ЛТМ» за тематикою – кардіологія, хірургія, гастроентерологія», ендокринологія, дерматовенерологія, неврологія, терапія, легеневі хвороби, дитяча ендокринологія, інфекційні хвороби, ендокринна хірургія, туберкульоз; </li>
            <li>міжнародні та національні клінічні настанови, рекомендації;</li>
            <li>актуальні новини, повідомлення, інформація від професійних медичних асоціацій, МОЗ України, Національної академії медичних наук України, Кабінету Міністрів України;</li>
            <li>вебінари за участю провідних українських учених, зарубіжних фахівців, спеціалізовані заходи з післядипломної освіти українських медиків (відеозаписи лекцій, семінарів, конференцій, майстер-класів).</li>
        </ul>
    </>

    const two = <>
        <p>Володіючи значною базою медичної інформації та сучасними технологічними комунікаційними можливостями, ТОВ «ВІТ-А-ПОЛ» максимально сприятиме українським медикам, фармацевтам, спеціалістам середньої ланки охорони здоров’я у наданні доступу до найновіших джерел як національної, так і зарубіжної медичної науки і практики.</p>
    </>

    return <div className="AboutSite">
        {zero}
        {one}
        {two}
    </div>
}

export default AboutSite;